import React from 'react';
import PropTypes from 'prop-types';
import { Pencil, Trash2 } from 'lucide-react';

const ItemsTable = ({ headers, data, showAddBtn, onAddItem, onDeleteItem, onEditItem }) => {

    const headerKeys = Object.keys(headers); // Get the keys from the headers object
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div className="form-heading">
                                <h4 className="mb-0">Items Details</h4>
                            </div>
                            {showAddBtn === true && (
                                <div className="add-group">
                                    <button
                                        onClick={onAddItem}
                                        className="btn btn-primary add-pluss ms-2"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Add Items"
                                    >
                                        <img src="/assets/img/icons/plus.svg" alt="add-icon" />
                                    </button>
                                </div>
                            )}

                        </div>
                        <div className="col-12 mt-3 table-responsive">
                            <table className="table border-0 mb-0 custom-table table-striped comman-table">
                                <thead>
                                    <tr>
                                        <th>Sr no</th>
                                        {headerKeys.map((key, index) => (
                                            <th key={index}>{key}</th>
                                        ))}
                                        <th className="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td>{rowIndex + 1}</td>
                                            {headerKeys.map((key, colIndex) => (
                                                <td key={colIndex} className='text-center'>
                                                    {row[headers[key]] ? row[headers[key]] : '-'}
                                                </td>
                                            ))}
                                            <td className="justify-content-end d-flex">
                                                            <button
                                                                className="action-icon mx-1"
                                                                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                                                onClick={() => onEditItem(rowIndex)}
                                                            >
                                                                <Pencil />
                                                            </button>
                                                            <button
                                                                className="action-icon mx-1"
                                                                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                                                onClick={() => onDeleteItem(rowIndex, row?.item_name)}
                                                            >
                                                                <Trash2 />
                                                            </button>
                                                        </td>
                                        </tr>
                                    ))}
                                    {data?.length === 0 ? (
                                        <tr>
                                            <td colspan="999">
                                                <div className="no-table-data">
                                                    Enter items!
                                                </div>
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
ItemsTable.propTypes = {
    headers: PropTypes.objectOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    showAddBtn: PropTypes.bool,
    onAddItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onEditItem: PropTypes.func,
};
ItemsTable.defaultProps = {
    showAddBtn: true,
};

export default ItemsTable;
