import axios from "axios";
import { V_URL } from "../../../BaseUrl";
import toast from "react-hot-toast";

export const DownloadXlsx = ({ apiMethod, url, body, fileName }) => {
    const myurl = `${V_URL}/user/${url}`;
    axios({
        method: apiMethod,
        data: body,
        url: myurl,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
        if (response?.data?.success === true) {
            const fileUrl = response.data.data.file;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            toast.success(response?.data.message);
        } else {
            toast.error(response?.data.message);
        }
    }).catch((error) => {
        console.log(error, '!!');
        toast.error(error?.response?.data.message);
    })
}