import { V_URL } from '../../BaseUrl';
import toast from 'react-hot-toast';
import axios from 'axios';

export const PdfDownloadErp = ({ apiMethod, url, body }) => {
    const myurl = `${V_URL}/user/${url}`;
    axios({
        method: apiMethod,
        url: myurl,
        data: body,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
        if (response?.data?.success) {
            const fileUrl = response.data.data.file;
            toast.success(response?.data?.message);
            window.open(fileUrl, '_blank');
        } else {
            toast.error(response?.data?.message);
        }
    }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Error occurred while downloading the file.');
    });
};