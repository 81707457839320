import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Building2, Calendar, ContactRound, LayoutDashboard, MailWarning, Presentation, ShieldCheck, UserPlus, Users } from 'lucide-react';


const Sidebar = () => {

    const location = useLocation();

    return (
        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll side-bar-scroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    <ul>
                        <li>
                            <Link to="/admin/dashboard" className={`${location.pathname === '/admin/dashboard' ? 'active' : ''}`}><span className="menu-side">
                                <LayoutDashboard className='Dash-iCon' />
                            </span> <span>Dashboard</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/firm-management" className={`${location.pathname === '/admin/firm-management' ||
                                location.pathname === '/admin/manage-firm' ? 'active' : ''}`}><span className="menu-side">
                                    {/* <img src="/assets/img/icons/firm.svg" alt="firm-img" /> */}
                                    <Building2 className='Dash-iCon' />
                                </span> <span>Firm</span></Link>
                        </li>
                        <li>
                            <Link to="/admin/year-management" className={`${location.pathname === '/admin/year-management' ||
                                location.pathname === '/admin/manage-year' ? 'active' : ''}`}><span className="menu-side">
                                    <Calendar className='Dash-iCon' />
                                </span> <span>Year</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/contractor-management" className={`${location.pathname === '/admin/contractor-management' ||
                                location.pathname === '/admin/manage-contractor' ? 'active' : ''}`}><span className="menu-side">
                                    <ContactRound className='Dash-iCon' />
                                </span> <span>Contractor</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/auth-people-management" className={`${location.pathname === '/admin/auth-people-management' ||
                                location.pathname === '/admin/manage-auth-people' ? 'active' : ''}`}><span className="menu-side" >
                                    <ShieldCheck className="Dash-iCon" /></span>
                                <span>Auth People</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/client-management" className={`${location.pathname === '/admin/client-management' ||
                                location.pathname === '/admin/manage-client' ? 'active' : ''}`}><span className="menu-side" >
                                    <UserPlus className="Dash-iCon" /></span>
                                <span>Client</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/department-management" className={`${location.pathname === '/admin/department-management' ||
                                location.pathname === '/admin/manage-department' ? 'active' : ''}`}><span className="menu-side" >
                                    <Building2 className="Dash-iCon" /></span>
                                <span>Department</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/project-management"
                                className={`${location.pathname === "/admin/project-management" || location.pathname === "/admin/manage-project" ? "active" : ""}`}>
                                <span className="menu-side">
                                    <Presentation className="Dash-iCon" />
                                </span>
                                <span>Project</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin/user-management" className={`${location.pathname === '/admin/user-management' ||
                                location.pathname === '/admin/manage-user' ? 'active' : ''}`}><span className="menu-side" >
                                    <Users className="Dash-iCon" /></span> <span>User</span></Link>
                        </li>

                        <li>
                            <Link to="/admin/verify-request-management" className={`${location.pathname === '/admin/verify-request-management' ||
                                location.pathname === '/admin/view-request'
                                ? 'active' : ''}`}><span className="menu-side" >
                                    <MailWarning className="Dash-iCon" /></span>
                                <span>Raw Material <br /> Request</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;