import React from 'react'

const TableHeader = () => {
    return (
        <thead>
            <tr>
                <th rowSpan="2">Activity</th>
                <th rowSpan="2">Date Performed</th>
                <th colSpan="4">Start</th>
                <th colSpan="4">Finish</th>
            </tr>
            <tr>
                <th>Surface or Metal Temp °C</th>
                <th>Dew Point °C</th>
                <th>Relative Humidity %</th>
                <th>Ambient Temp °C</th>
                <th>Surface or Metal Temp °C</th>
                <th>Dew Point °C</th>
                <th>Relative Humidity %</th>
                <th>Ambient Temp °C</th>
            </tr>
        </thead>
    )
}

export default TableHeader