import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Include/Footer';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import moment from 'moment';

const ManageInspection = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [drawing, setDrawing] = useState({});

    const data = location.state;

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
    }, []);

    useEffect(() => {
        if (data) {
            setDrawing((data));
        }
    }, [data]);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/project-store/dashboard">Dashboard </Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item"><Link to="/user/project-store/inspection-summary-management">Inspection Summary Records List</Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">View Report Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="col-12">
                                            <div className="form-heading">
                                                <h4> Report Details</h4>
                                            </div>
                                        </div>

                                        {drawing?._id ? (
                                            <>
                                                <div className='row'>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Client No.</label>
                                                            <input className="form-control" value={drawing?.drawing_id?.project?.party?.name} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Project PO No.</label>
                                                            <input className="form-control" value={drawing?.drawing_id?.project?.work_order_no} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Report Date</label>
                                                            <input className="form-control" value={moment(drawing?.summary_date).format('YYYY-MM-DD')} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Drawing No.</label>
                                                            <input className="form-control" value={drawing?.drawing_id?.drawing_no} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>REV</label>
                                                            <input className="form-control" value={drawing?.drawing_id?.rev} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Assembly No.</label>
                                                            <input className="form-control" value={drawing?.drawing_id?.assembly_no} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-xl-6">
                                                        <div className="input-block local-forms">
                                                            <label>Fitup Inspection Report</label>
                                                            <input className="form-control" value={drawing?.fitup_inspection_report} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-6">
                                                        <div className="input-block local-forms">
                                                            <label>Weld Visual Inspection Report</label>
                                                            <input className="form-control" value={drawing?.weld_inspection_report} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>UT Report</label>
                                                            <input className="form-control" value={drawing?.ut_report || '-'} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>RT Report</label>
                                                            <input className="form-control" value={drawing?.rt_report || '-'} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>LPT Report</label>
                                                            <input className="form-control" value={drawing?.lpt_report || '-'} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-xl-6">
                                                        <div className="input-block local-forms">
                                                            <label>MPT Report</label>
                                                            <input className="form-control" value={drawing?.mpt_report || '-'} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-6">
                                                        <div className="input-block local-forms">
                                                            <label>Final Dimension Inspection Report</label>
                                                            <input className="form-control" value={drawing?.fd_report || '-'} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        <div className="col-12">
                                            <div className="doctor-submit text-end">
                                                <button type="button" className="btn btn-primary submit-form me-2"
                                                    onClick={() => navigate('/user/project-store/inspection-summary-management')} >
                                                    Back
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ManageInspection
